import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { compose, createStore, applyMiddleware } from 'redux'
import camelcaseKeys from 'camelcase-keys'
import rootReducer from 'reducers/index'
import borrowerDashboard from 'apiV2/store/borrower_dashboard'
import baseInitialState from 'apiV2/store/base/initialState'

// Create one single shared Redux store
export const composeEnhancers = process.env.NODE_ENV !== 'production'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

export const findReduxNodes = () => {
  return document.querySelectorAll("[data-integration-name='react-redux-component']")
}

export const getJSONDataAttribute = (element, key) => (element.dataset[key] ? JSON.parse(element.dataset[key]) : {})

const mergeBaseStateWithInjectedState = injectedState => {
  const preloadedStateWithBase = {}
  const stateKeys = Object.keys(injectedState)
  stateKeys.forEach(key => {
    preloadedStateWithBase[key] = { ...baseInitialState, ...injectedState[key] }
  })

  return preloadedStateWithBase
}

export const buildInitialState = (nodeList) => {
  let initialState = {}

  // ie11 polyfill
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach
  }

  nodeList.forEach((domNode) => {
    const options = getJSONDataAttribute(domNode, 'options')
    initialState = {
      ...initialState,
      ...(options.initialState ? mergeBaseStateWithInjectedState(options.initialState) : {}),
    }
  })
  return initialState
}

export const renderReduxComponent = (domElement, componentList) => {
  const options = getJSONDataAttribute(domElement, 'options')
  const componentName = options.name
  const payload = getJSONDataAttribute(domElement, 'payload')
  const containerElement = React.createElement(componentList[componentName], payload)

  ReactDOM.render(
    React.createElement(Provider, { store: window.store }, containerElement),
    domElement
  )
}

export const renderMainApplication = (componentList, reduxMiddlewares = []) => {
  window.addEventListener('load', () => {
    const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, ...reduxMiddlewares))
    const reduxNodes = findReduxNodes()
    const initialState = buildInitialState(reduxNodes)

    window.store = createStore(
      rootReducer,
      initialState,
      enhancer,
    )
    reduxNodes.forEach(element => renderReduxComponent(element, componentList))
  })
}

export const renderBorrowerDashboard = (componentList, reduxMiddlewares = []) => {
  window.addEventListener('load', () => {
    const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, ...reduxMiddlewares))
    const reduxNodes = findReduxNodes()
    const initialState = buildInitialState(reduxNodes)

    window.store = createStore(
      borrowerDashboard,
      camelcaseKeys(initialState, { deep: true }),
      enhancer,
    )
    reduxNodes.forEach(element => renderReduxComponent(element, componentList))
  })
}

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js').then((registration) => {
      // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }, (err) => {
      // registration failed :(
        console.log('ServiceWorker registration failed: ', err)
      })
    })
  }
}
