import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { updateTaskAPI } from './asyncActions'

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: baseInitialState,
  reducers: {
    ...baseReducers,
    attachDocumentToTask(state, action) {
      const { payload: attachment, payload: { documentGroupId } } = action

      const record = state.records.find(r => String(r.id) === String(documentGroupId))
      if (record) {
        record.documents.push(attachment)
        if (record.id === state.stagedRecord.id) state.stagedRecord = record
      }
    },
    addArgyleConnectionToTask(state, action) {
      const {
        argyleAccount: existingArgyleAccount,
        argyleAccountId,
        connection,
      } = action.payload

      state.records.filter(({ argyleTask }) => argyleTask).forEach(record => {
        const { argyleAccounts } = record
        const argyleAccount = argyleAccounts.find(({ id }) => +id === +argyleAccountId)
        if (argyleAccount) {
          const { connections } = argyleAccount
          const connectionIndex = connections.findIndex(({ accountId }) => accountId === connection.accountId)
          if (connectionIndex !== -1) {
            connections[connectionIndex] = { ...connection, connectionStatus: 'processing' }
          } else {
            connections.push({ ...connection, connectionStatus: 'processing' })
          }
        } else {
          const { connections } = existingArgyleAccount
          const connectionIndex = connections.findIndex(({ accountId }) => accountId === connection.accountId)
          if (connectionIndex !== -1) {
            connections[connectionIndex] = { ...connection, connectionStatus: 'processing' }
          } else {
            connections.push({ ...connection, connectionStatus: 'processing' })
          }
          existingArgyleAccount.connections = connections
          record.argyleAccounts.push(existingArgyleAccount)
        }
        if (record.id === state.stagedRecord.id) state.stagedRecord = record
      })
    },
    removeArgyleConnectionFromTask(state, action) {
      const { argyleAccountId, connection } = action.payload

      state.records.filter(({ argyleTask }) => argyleTask).forEach(record => {
        const { argyleAccounts } = record
        const argyleAccount = argyleAccounts.find(({ id }) => +id === +argyleAccountId)
        const { connections } = argyleAccount
        const connectionIndex = connections.findIndex(({ accountId }) => accountId === connection.accountId)
        if (connectionIndex !== -1) {
          connections.splice(connectionIndex, 1)
        }
        if (record.id === state.stagedRecord.id) state.stagedRecord = record
      })
    },
    updateTask(state, action) {
      if (+state.stagedRecord.id === +action.payload.id) state.stagedRecord = action.payload
      const recordIndex = state.records.findIndex(({ id }) => +id === +action.payload.id)
      if (recordIndex) state.records[recordIndex] = { ...state.records[recordIndex], ...action.payload }
    },
    updateTaskArgyleAccount(state, action) {
      const { argyleAccount } = action.payload

      state.records.filter(({ argyleTask }) => argyleTask).forEach(record => {
        const { argyleAccounts = [] } = record
        const argyleAccountIndex = argyleAccounts.findIndex(({ id }) => +id === +argyleAccount.id)
        if (argyleAccountIndex !== -1) {
          record.argyleAccounts[argyleAccountIndex] = argyleAccount
        } else {
          record.argyleAccounts = [ argyleAccount ]
        }
        if (record.id === state.stagedRecord.id) state.stagedRecord = record
      })
    },
    updateTaskDocuments(state, action) {
      const { documentGroupId, documents } = action.payload

      if (+state.stagedRecord.id === +documentGroupId) {
        state.stagedRecord.documents = documents
      }
      const record = state.records.find(({ id }) => +id === +documentGroupId)
      if (record) record.documents = documents
    },
    updateArgyleTaskDocuments(state, action) {
      const { documents } = action.payload

      state.records.filter(({ argyleTask }) => argyleTask).forEach(record => {
        const taskDocuments = documents.filter(({ documentGroupId }) => +documentGroupId === +record.id)
        record.documents = taskDocuments
        if (record.id === state.stagedRecord.id) state.stagedRecord = record
      })
    },
    updateTaskFinicityAccount(state, action) {
      const { documentGroupId, customerInstitutionLogins } = action.payload

      if (+state.stagedRecord.id === +documentGroupId) {
        const { finicityCustomerInstitutionLogins } = state.stagedRecord
        const finicityAccountIndex = finicityCustomerInstitutionLogins?.length - 1
        if (finicityAccountIndex !== -1) {
          finicityCustomerInstitutionLogins[finicityAccountIndex] = customerInstitutionLogins
        } else {
          finicityCustomerInstitutionLogins.push(customerInstitutionLogins)
        }
      }

      const recordIndex = state.records.findIndex(({ id }) => +id === +documentGroupId)
      if (recordIndex) state.records[recordIndex] = { ...state.records[recordIndex], ...action.payload }
    },
    removeFinicityConnectionFromTask(state, action) {
      const { documentGroupId } = action.payload

      if (+state.stagedRecord.id === +documentGroupId) {
        const { finicityCustomerInstitutionLogins } = state.stagedRecord
        const finicityAccountIndex = finicityCustomerInstitutionLogins?.length - 1
        if (finicityAccountIndex !== -1) {
          delete finicityCustomerInstitutionLogins[finicityAccountIndex]
        }
      }
      const record = state.records.find(({ id }) => +id === +documentGroupId)
      if (record) {
        const { finicityCustomerInstitutionLogins } = record
        const finicityAccountIndex = finicityCustomerInstitutionLogins?.length - 1
        if (finicityAccountIndex !== -1) {
          delete finicityCustomerInstitutionLogins[finicityAccountIndex]
        }
      }
    },
  },
  extraReducers: {
    [updateTaskAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateTaskAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      const { id } = action.payload
      state.records = state.records.map(task => (task.id === id ? action.payload : task))
      state.stagedRecord = action.payload
    },
    [updateTaskAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default tasksSlice
export const tasksReducer = tasksSlice.reducer
