import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { createPaymentIntentAPI, updatePaymentIntentAPI } from './asyncActions'

const paymentIntentsSlice = createSlice({
  name: 'payment_intents',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createPaymentIntentAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createPaymentIntentAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = action.payload
    },
    [createPaymentIntentAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [updatePaymentIntentAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updatePaymentIntentAPI.fulfilled](state) {
      state.uiFlags.isUpdating = false
    },
    [updatePaymentIntentAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default paymentIntentsSlice
