import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  createDocumentsAPI,
  indexDocumentsAPI,
  destroyDocumentsAPI,
  createLetterAPI,
} from './asyncActions'

const documentsSlice = createSlice({
  name: 'documents',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexDocumentsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexDocumentsAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexDocumentsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createDocumentsAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createDocumentsAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [createDocumentsAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [destroyDocumentsAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyDocumentsAPI.fulfilled](state) {
      state.uiFlags.isDeleting = false
    },
    [destroyDocumentsAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
    [createLetterAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createLetterAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [createLetterAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default documentsSlice
