import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

const brandingSlice = createSlice({
  name: 'branding',
  initialState: baseInitialState,
  reducers: baseReducers,
})

export default brandingSlice
export const brandingReducer = brandingSlice.reducer
