import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createVOEISignAuthURL = createAsyncThunk(
  'voei_requests/auth_signing_url/create',
  async ({ requestId }) => {
    const { data } = await JSONAPIClient
      .one('request', requestId)
      .all('auth_signing_url')
      .post({ requestId })
    return data
  }
)
