import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexDisclosuresTasksAPI = createAsyncThunk(
  'disclosures_tasks/index',
  async ({ loanFileId, filter = {} }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('disclosures_tasks')
      .get({ include: 'disclosures_task_items', filter })

    return data
  }
)

export const showDisclosuresTaskAPI = createAsyncThunk(
  'disclosures_tasks/show',
  async ({ loanFileId, disclosuresTaskId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .one('disclosures_task', disclosuresTaskId)
      .get({ include: 'disclosures_task_items' })

    return data
  }
)
