import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { createVOEISignAuthURL } from './asyncActions'

const voeiAuthSignURLSlice = createSlice({
  name: 'voei_auth_signing_url',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createVOEISignAuthURL.pending](state) {
      state.uiFlags.isFetching = true
    },
    [createVOEISignAuthURL.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [createVOEISignAuthURL.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default voeiAuthSignURLSlice
