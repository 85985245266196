import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexVOEITasksAPI } from './asyncActions'

const voeiTasksSlice = createSlice({
  name: 'voei_tasks',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexVOEITasksAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexVOEITasksAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexVOEITasksAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default voeiTasksSlice
