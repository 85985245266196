import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexDocumentGroupsAPI = createAsyncThunk(
  'document_groups/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('document_group')
      .get({ include: 'documents' })
    return data
  }
)

export const showDocumentGroupAPI = createAsyncThunk(
  'document_group/show',
  async ({ loanFileId, documentGroupId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .one('document_group', documentGroupId)
      .get({ include: 'documents' })
    return data
  }
)

export const updateDocumentGroupAPI = createAsyncThunk(
  'document_group/update',
  async ({ loanFileId, documentGroupId, payload }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .one('document_group', documentGroupId)
      .patch({ id: documentGroupId, ...payload }, { include: 'documents' })
    return data
  }
)
