import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexVOATasksAPI = createAsyncThunk(
  'voa_tasks/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('voa_task')
      .get()
    return data
  }
)

export const updateVOATasksAPI = createAsyncThunk(
  'voa_tasks/update',
  async ({ loanFileId, taskId, payload }, thunkApi) => {
    try {
      const { data } = await JSONAPIClient
        .one('loan_file', loanFileId)
        .one('voa_task', taskId)
        .patch({ id: taskId, ...payload })
      thunkApi.dispatch(popToast({ notice: 'Success, the task has been submitted for review' }))
      return data
    } catch (error) {
      thunkApi.dispatch(popToast({ error: 'There was a problem submitting task for review' }))
      return {}
    }

  }
)
