import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from 'apiV2/apiClient/axiosClient'
import Route from 'Services/Route'
import { popToast } from 'v2/actions/system_notifications'

export const indexDownloadsAPI = createAsyncThunk(
  'downloads/index',
  async ({ loanFileId, apiToken }) => {
    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.downloads.index({ id: loanFileId })
    const headers = { Authorization: `Token ${apiToken}` }
    const { data: { downloads = [] } } = await axiosClient.get(url, { headers, baseURL: '' })
    return downloads
  }
)

export const createDownloadAPI = createAsyncThunk(
  'download/create',
  async ({ loanFileId, attachmentIds, apiToken }, thunkApi) => {
    try {
      // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
      const url = Route.api.downloads.index({ id: loanFileId })
      const headers = { Authorization: `Token ${apiToken}` }
      const { data: { download } } = await axiosClient.post(
        url, { attachment_ids: attachmentIds }, { headers, baseURL: '' }
      )
      return download
    } catch (error) {
      const { errors } = error?.response?.data || {}
      if (errors) {
        thunkApi.dispatch(popToast({ error: errors }))
        return errors
      }
      return error
    }
  }
)
