import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexDisclosuresTaskItemsAPI = createAsyncThunk(
  'disclosures_task_items/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient.one('loan_file', loanFileId).all('disclosures_task_items').get()

    return data
  }
)

export const updateDisclosuresTaskItemsAPI = createAsyncThunk(
  'disclosures_task_items/update',
  async ({ loanFileId, disclosuresTaskItemId, payload }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .one('disclosures_task_item', disclosuresTaskItemId)
      .patch({ id: disclosuresTaskItemId, ...payload })

    return data
  }
)

export const createDisclosuresTaskItemsSSOTokenAPI = createAsyncThunk(
  'disclosures_task_items/docutech_sso_tokens/create',
  async ({ disclosuresTaskItemId }) => {
    const { data } = await JSONAPIClient
      .one('disclosures_task_item', disclosuresTaskItemId)
      .all('docutech_sso_token').post({})
    return data
  }
)
