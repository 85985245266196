import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createPaymentIntentAPI = createAsyncThunk(
  'payment_intents/create',
  async ({ paymentTaskId }) => {
    const { data } = await JSONAPIClient.all('payment_intent').post({ paymentTaskId })
    return data
  }
)

export const updatePaymentIntentAPI = createAsyncThunk(
  'payment_intents/update',
  async ({ paymentTaskId }) => {
    const { data } = await JSONAPIClient.one('payment_intent', paymentTaskId)
      .patch({ paymentTaskId, id: paymentTaskId })
    return data
  }
)
