import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from 'apiV2/apiClient/axiosClient'
import Route from 'Services/Route'

export const indexCommunicationsAPI = createAsyncThunk(
  'communications/index',
  async ({ loanFileId, apiToken }) => {
    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.messenger.communications({ id: loanFileId })
    const headers = { Authorization: `Token ${apiToken}` }
    const response = await axiosClient.get(url, { headers, baseURL: '' })
    const { communications } = response.data
    return communications
  }
)

export const showCommunicationsAPI = createAsyncThunk(
  'communications/show',
  async ({ loanFileId, communicationId, apiToken }) => {
    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = `${Route.api.messenger.communications({ id: loanFileId })}/${communicationId}`
    const headers = { Authorization: `Token ${apiToken}` }
    const response = await axiosClient.get(url, { headers, baseURL: '' })
    const { communication } = response.data
    return communication
  }
)

export const createCommunicationAPI = createAsyncThunk(
  'communications/create',
  async ({ loanFileId, message, poolType = 'borrower', apiToken }) => {
    const payload = {
      message: message,
      pool_type: poolType,
    }
    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.messenger.communications({ id: loanFileId })
    const headers = { Authorization: `Token ${apiToken}` }
    const response = await axiosClient.post(url, payload, { headers, baseURL: '' })
    const { communication } = response.data
    return communication
  }
)
