import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexVOATasksAPI, updateVOATasksAPI } from './asyncActions'

const voaTasksSlice = createSlice({
  name: 'voa_tasks',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexVOATasksAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexVOATasksAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexVOATasksAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateVOATasksAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateVOATasksAPI.fulfilled](state, action) {
      const { payload, payload: { id } } = action
      state.records = state.records.map(record => (record.id === id ? payload : record))

      if (state.stagedRecord.id === id) state.stagedRecord = payload

      state.uiFlags.isUpdating = false
    },
    [updateVOATasksAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default voaTasksSlice
