import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexDisclosuresTasksAPI, showDisclosuresTaskAPI } from './asyncActions'

const disclosuresTasksSlice = createSlice({
  name: 'disclosures_tasks',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexDisclosuresTasksAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexDisclosuresTasksAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexDisclosuresTasksAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showDisclosuresTaskAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showDisclosuresTaskAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      const index = state.records.findIndex(({ id }) => +id === +action.payload.id)
      if (index !== -1) {
        state.records[index] = action.payload
      } else {
        state.records.push(action.payload)
      }
    },
    [showDisclosuresTaskAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default disclosuresTasksSlice
