import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { toastrErrors } from 'utils/error_utils'
import toastr from 'utils/toastr'

export const createInsuranceRequestAPI = createAsyncThunk(
  'insurance_quote/create',
  async ({ taskId, payload }) => {
    try {
      const { data } = await JSONAPIClient.all('insurance_quote').post({ taskId, ...payload })
      toastr.success('Your information was successfully submitted. You will receive your quotes soon.')
      return data
    } catch (error) {
      if (error) toastrErrors({ error: 'We were unable to submit your data, please try again or contact support.' })
      return {}
    }
  }
)
