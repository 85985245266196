import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  createDownloadAPI,
  indexDownloadsAPI,
} from './asyncActions'

const downloadsSlice = createSlice({
  name: 'downloads',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexDownloadsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexDownloadsAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexDownloadsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createDownloadAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createDownloadAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.records.push(action.payload)
    },
    [createDownloadAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default downloadsSlice
