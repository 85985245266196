import { combineReducers } from 'redux'
import brandingSlice from 'apiV2/store/brand'
import closingTasksSlice from 'apiV2/store/closingTasks'
import communicationsSlice from 'apiV2/store/communications'
import connectionSlice from 'apiV2/store/connection'
import disclosuresTasksSlice from 'apiV2/store/disclosuresTasks'
import disclosuresTaskItemsSlice from 'apiV2/store/disclosuresTaskItems'
import documentsSlice from 'apiV2/store/documents'
import documentGroupsSlice from 'apiV2/store/documentGroups'
import downloadsSlice from 'apiV2/store/downloads'
import getstreamReducerSlice from 'apiV2/store/getstream'
import insuranceQuoteSlice from 'apiV2/store/insuranceQuote'
import loanFileSlice from 'apiV2/store/loanFiles'
import pageInfoSlice from 'apiV2/store/pageInfo'
import paymentIntentsSlice from 'apiV2/store/paymentIntents'
import paymentTasksSlice from 'apiV2/store/paymentTasks'
import signableDocumentsSlice from 'apiV2/store/signableDocuments'
import statementImportSlice from 'apiV2/store/statementImport'
import tasksSlice from 'apiV2/store/tasks'
import taskItemsSlice from 'apiV2/store/loanApplications/taskItem'
import userSlice from 'apiV2/store/user'
import voaTasksSlice from 'apiV2/store/voa/voaTasks'
import plaidLinkTokenSlice from 'apiV2/store/voa/plaidLinkToken'
import voeiTasksSlice from 'apiV2/store/voei/tasks'
import voeiRequestsSlice from 'apiV2/store/voei/requests'
import voeiAuthSignURLSlice from 'apiV2/store/voei/authSigningUrl'
import systemNotifications from 'v2/reducers/system_notifications'

const v2 = combineReducers({ systemNotifications })

const borrowerDashboard = combineReducers({
  branding: brandingSlice.reducer,
  communications: communicationsSlice.reducer,
  closingTasks: closingTasksSlice.reducer,
  connections: connectionSlice.reducer,
  disclosuresTasks: disclosuresTasksSlice.reducer,
  disclosuresTaskItems: disclosuresTaskItemsSlice.reducer,
  documents: documentsSlice.reducer,
  documentGroups: documentGroupsSlice.reducer,
  loan_app_task_items: taskItemsSlice.reducer,
  downloads: downloadsSlice.reducer,
  getstreamReducer: getstreamReducerSlice.reducer,
  insuranceQuote: insuranceQuoteSlice.reducer,
  loanFiles: loanFileSlice.reducer,
  pageInfo: pageInfoSlice.reducer,
  paymentIntents: paymentIntentsSlice.reducer,
  paymentTasks: paymentTasksSlice.reducer,
  signableDocuments: signableDocumentsSlice.reducer,
  statementImports: statementImportSlice.reducer,
  tasks: tasksSlice.reducer,
  user: userSlice.reducer,
  voaTasks: voaTasksSlice.reducer,
  plaidLinkTokens: plaidLinkTokenSlice.reducer,
  voeiTasks: voeiTasksSlice.reducer,
  voeiRequests: voeiRequestsSlice.reducer,
  voeiAuthSignUrl: voeiAuthSignURLSlice.reducer,
  v2,
})

export default borrowerDashboard
