import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexSignableDocumentsAPI = createAsyncThunk(
  'signable_documents/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient.one('loan_file', loanFileId).all('signable_documents').get()

    return data
  }
)

export const showSignableDocumentAPI = createAsyncThunk(
  'signable_documents/show',
  async ({ loanFileId, signableDocumentId }) => {
    const { data } = await JSONAPIClient.one('loan_file', loanFileId)
      .one('signable_documents', signableDocumentId).get()

    return data
  }
)
