import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexDocumentGroupsAPI, updateDocumentGroupAPI, showDocumentGroupAPI } from './asyncActions'

const documentGroupsSlice = createSlice({
  name: 'document_groups',
  initialState: baseInitialState,
  reducers: {
    ...baseReducers,
    attachDocumentToDocumentGroup(state, action) {
      const { payload: attachment, payload: { documentGroupId } } = action

      const record = state.records.find(r => +r.id === +documentGroupId)
      if (record) record.documents.push(attachment)
    },
  },
  extraReducers: {
    [indexDocumentGroupsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexDocumentGroupsAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexDocumentGroupsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showDocumentGroupAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showDocumentGroupAPI.fulfilled](state, action) {
      const { payload, payload: { id } } = action
      state.records = state.records.map(record => (+record.id === +id ? payload : record))

      if (+state.stagedRecord.id === +id) state.stagedRecord = payload

      state.uiFlags.isFetchingItem = false
    },
    [showDocumentGroupAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
    [updateDocumentGroupAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateDocumentGroupAPI.fulfilled](state, action) {
      const { payload, payload: { id } } = action
      state.records = state.records.map(record => (record.id === id ? payload : record))

      if (state.stagedRecord.id === id) state.stagedRecord = payload

      state.uiFlags.isUpdating = false
    },
    [updateDocumentGroupAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default documentGroupsSlice
