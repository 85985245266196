import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createPlaidLinkTokenAPI = createAsyncThunk(
  'plaid_link_token/create',
  async ({ products, itemId, useTeamSettings, loanFileId }) => {
    try {
      const { data } = await JSONAPIClient
        .one('loan_file', loanFileId)
        .all('plaid_link_token')
        .post({ products, itemId, useTeamSettings })
      return data
    } catch (error) {
      return error
    }

  }
)
