import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { connectionConnectUrlAPI, connectionFixConnectUrlAPI } from './asyncActions'

export const connectionSlice = createSlice({
  name: 'connection',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [connectionConnectUrlAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [connectionConnectUrlAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isCreating = false
    },
    [connectionConnectUrlAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [connectionFixConnectUrlAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [connectionFixConnectUrlAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isUpdating = false
    },
    [connectionFixConnectUrlAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default connectionSlice
