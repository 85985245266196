import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexDisclosuresTaskItemsAPI, updateDisclosuresTaskItemsAPI } from './asyncActions'

const disclosuresTaskItemsSlice = createSlice({
  name: 'disclosures_task_items',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexDisclosuresTaskItemsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexDisclosuresTaskItemsAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexDisclosuresTaskItemsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateDisclosuresTaskItemsAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateDisclosuresTaskItemsAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = action.payload
    },
    [updateDisclosuresTaskItemsAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default disclosuresTaskItemsSlice
