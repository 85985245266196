import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexVOEITasksAPI = createAsyncThunk(
  'voei_tasks/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('voei_task')
      .get()
    return data
  }
)
