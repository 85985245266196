import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from 'apiV2/apiClient/axiosClient'
import api from 'utils/api'
import Route from 'Services/Route'
import { getStagedUser } from './selectors'

export const updateUserProfileImage = createAsyncThunk(
  'user/profile_image/update',
  async (payload, { getState }) => {
    const { id: userId } = getStagedUser(getState())
    const headers = { 'Content-Type': 'multipart/form-data' }
    const response = await axiosClient.patch(`users/${userId}/profile_image`, payload, { headers })

    return response.data
  }
)

export const destroyUserProfileImage = createAsyncThunk(
  'user/profile_image/destroy',
  async (_, { getState }) => {
    const { id: userId } = getStagedUser(getState())
    const response = await axiosClient.delete(`users/${userId}/profile_image`)

    return response.data
  }
)

export const toggleTwoFactorAuth = createAsyncThunk(
  'user/toggle_two_factor_auth',
  async (_, { getState }) => {
    const url = Route.api.twoFactorAuths.toggle()
    const response = await api(getState).post(url)

    return response.data
  }
)

export const requestTwoFactorCode = createAsyncThunk(
  'user/request_two_factor_code',
  async (phone, { getState }) => {
    const url = Route.api.twoFactorAuths.send_otp()
    const response = await api(getState).post(url, { user: { phone } })

    return response.data
  }
)

export const confirmOTP = createAsyncThunk(
  'user/confirm_otp',
  async (code, { getState, rejectWithValue }) => {
    const url = Route.api.twoFactorAuths.confirm_otp()
    const response = await api(getState).post(url, { user: { otp_attempt: code } })

    if (response.data.alert) {
      return rejectWithValue(response.data)
    }

    return response.data
  }
)

export const resendOTP = createAsyncThunk('user/resend_otp', async (_, { getState }) => {
  const url = Route.api.twoFactorAuths.resend_otp()
  const response = await api(getState).post(url)

  return response.data
})
