import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { indexCommunicationsAPI, createCommunicationAPI, showCommunicationsAPI } from './asyncActions'

const communicationsSlice = createSlice({
  name: 'communications',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexCommunicationsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexCommunicationsAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexCommunicationsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createCommunicationAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createCommunicationAPI.fulfilled](state, action) {
      const { id } = action.payload
      const communicationIndex = state.records.findIndex(
        ({ id: communicationId }) => String(communicationId) === String(id)
      )
      if (communicationIndex === -1) {
        state.records.push(action.payload)
      }
      state.uiFlags.isCreating = false
    },
    [createCommunicationAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [showCommunicationsAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showCommunicationsAPI.fulfilled](state, action) {
      const { id } = action.payload
      const communicationIndex = state.records.findIndex(
        ({ id: communicationId }) => String(communicationId) === String(id)
      )
      if (communicationIndex !== -1) {
        state.records[communicationIndex] = action.payload
      } else {
        state.records.push(action.payload)
      }
      state.uiFlags.isFetchingItem = false
    },
    [showCommunicationsAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
  },
})

export default communicationsSlice
export const communicationsReducer = communicationsSlice.reducer
