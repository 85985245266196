import axios from 'axios'
import Cookies from 'js-cookie'

const doorKeeperToken = Cookies.get('maxwell_api_v2_token')

const axiosClient = axios.create({
  baseURL: '/api/v2',
  headers: { Authorization: `Bearer ${doorKeeperToken}` },
  data: {},
})

export default axiosClient
