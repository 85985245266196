import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from 'apiV2/apiClient/axiosClient'
import Route from 'Services/Route'
import { popToast } from 'v2/actions/system_notifications'
import communicationsSlice from 'apiV2/store/communications'
import JSONAPIClient from 'apiV2/apiClient'
import { indexDocumentGroupsAPI, showDocumentGroupAPI } from 'apiV2/store/documentGroups/asyncActions'

const { actions: { add: addCommunication } } = communicationsSlice

export const indexDocumentsAPI = createAsyncThunk(
  'documents/index',
  async ({ loanFileId, apiToken }) => {
    const url = Route.api.loanFiles.attachments.index({ loanFileId })
    const headers = { Authorization: `Token ${apiToken}` }
    const { data: { attachments = [] } } = await axiosClient.get(url, { headers, baseURL: '' })
    return attachments
  }
)

export const createDocumentsAPI = createAsyncThunk(
  'documents/create',
  async ({ loanFileId, message, attachments, apiToken, showChat }, thunkApi) => {
    try {
      const url = Route.api.messenger.attachments({ loanFileId })
      const headers = { Authorization: `Token ${apiToken}` }
      const payload = new FormData()
      payload.append('message', message)
      attachments.forEach(attachment => payload.append('attachments[]', attachment))
      const response = await axiosClient.post(url, payload, { headers, baseURL: '' })
      const { communication } = response.data
      thunkApi.dispatch(addCommunication(communication))
      thunkApi.dispatch(indexDocumentsAPI({ loanFileId, apiToken }))
      if (!showChat) {
        thunkApi.dispatch(popToast({ notice: 'Success! Documents uploaded' }))
      }
      return response
    } catch (error) {
      const { errors } = error?.response?.data || {}
      if (errors) {
        thunkApi.dispatch(popToast({ error: errors }))
        return errors
      }
      return error
    }
  }
)

export const destroyDocumentsAPI = createAsyncThunk(
  'documents/destroy',
  async ({ loanFileId, attachmentIds, apiToken }, thunkApi) => {
    try {
      const url = Route.api.loanFiles.attachments.bulkDelete({ loanFileId })
      const headers = { Authorization: `Token ${apiToken}` }
      const { data: { attachments } } = await axiosClient
        .delete(url, { headers, baseURL: '', data: { attachment_ids: attachmentIds } })

      thunkApi.dispatch(indexDocumentsAPI({ loanFileId, apiToken }))
      thunkApi.dispatch(indexDocumentGroupsAPI({ loanFileId }))
      thunkApi.dispatch(popToast({ notice: 'Success! Documents removed' }))
      return attachments
    } catch (error) {
      const { errors } = error?.response?.data || {}
      if (errors) {
        thunkApi.dispatch(popToast({ error: errors }))
        return errors
      }
      return error
    }
  }
)

// For task document uploads
export const createDocumentAPI = createAsyncThunk(
  'document/create',
  async ({ loanFileId, file, taskId, category, losType, apiToken, fileName }, thunkApi) => {
    const url = Route.api.documentGroups.upload({ loanFileId, documentGroupId: taskId })
    const headers = { Authorization: `Token ${apiToken}` }

    const payload = new FormData()
    payload.append('document_key', fileName || file.name)
    payload.append('category', category.toLowerCase())
    payload.append('document_file', file, fileName || file.name)
    if (losType) payload.append('los_type', losType)

    const response = await axiosClient.post(url, payload, { headers, baseURL: '' })
    thunkApi.dispatch(showDocumentGroupAPI({ loanFileId, documentGroupId: taskId }))
    return response
  }
)

export const createLetterAPI = createAsyncThunk(
  'document/create',
  async ({ taskId, payload }, thunkApi) => {
    const { loanFileId, content, ...documentParams } = payload
    const { data } = await JSONAPIClient.one('document_group', taskId).all('document').post(
      documentParams,
      {},
      { loanFileId, documentType: 'letter', content },
    )
    thunkApi.dispatch(showDocumentGroupAPI({ loanFileId, documentGroupId: taskId }))
    return data
  }
)
