import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  updateUserProfileImage,
  destroyUserProfileImage,
  toggleTwoFactorAuth,
  requestTwoFactorCode,
  confirmOTP,
  resendOTP,
} from './asyncActions'

const userSlice = createSlice({
  name: 'user',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [updateUserProfileImage.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateUserProfileImage.fulfilled](state, action) {
      state.stagedRecord.profileImageSrc = action.payload.profileImageSrc
      state.uiFlags.isUpdating = false
    },
    [updateUserProfileImage.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [destroyUserProfileImage.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [destroyUserProfileImage.fulfilled](state, action) {
      state.stagedRecord.profileImageSrc = action.payload.profileImageSrc
      state.uiFlags.isUpdating = false
    },
    [destroyUserProfileImage.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [toggleTwoFactorAuth.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [toggleTwoFactorAuth.fulfilled](state) {
      state.stagedRecord.otpRequiredForLogin = !state.stagedRecord.otpRequiredForLogin
      state.uiFlags.isUpdating = false
    },
    [toggleTwoFactorAuth.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [requestTwoFactorCode.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [requestTwoFactorCode.fulfilled](state, action) {
      state.stagedRecord.phone = action.meta.arg
      state.stagedRecord.mobilePhone2faVerified = false
      state.uiFlags.isUpdating = false
    },
    [requestTwoFactorCode.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [confirmOTP.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [confirmOTP.fulfilled](state) {
      state.stagedRecord.mobilePhone2faVerified = true
      state.uiFlags.isUpdating = false
    },
    [confirmOTP.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [resendOTP.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [resendOTP.fulfilled](state) {
      state.uiFlags.isUpdating = false
    },
    [resendOTP.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default userSlice
export const userReducer = userSlice.reducer
