import { createAsyncThunk } from '@reduxjs/toolkit'
import Route from 'Services/Route'
import { putRequest } from 'utils/api'

export const updateTaskAPI = createAsyncThunk(
  'tasks/update',
  async ({ taskId, loanFileId, payload }, thunkAPI) => {
    const { getState } = thunkAPI

    const { api: { tasks: { update } } } = Route
    const url = update({ loanFileId, taskId })

    const { data: { task } } = await putRequest({ getState, url, params: payload })

    return task
  }
)
