import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from '../../apiClient'

export const connectionConnectUrlAPI = createAsyncThunk(
  'connection/connect_url',
  async ({ taskId }) => {
    try {
      const { data } = await JSONAPIClient.all('connection').post({ taskId })
      return data
    } catch {
      return {}
    }
  }
)

export const connectionFixConnectUrlAPI = createAsyncThunk(
  'connection/fix_connect_url',
  async ({ taskId, customerInstitutionLoginId }) => {
    try {
      const { data } = await JSONAPIClient.all('connection').post({ taskId, customerInstitutionLoginId })
      return data
    } catch {
      return {}
    }
  }
)
