import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexPaymentTasksAPI = createAsyncThunk(
  'payment_tasks/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('payment_task')
      .get()
    return data
  }
)
