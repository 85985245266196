import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from 'apiV2/apiClient/axiosClient'
import Route from 'Services/Route'

export const resetUnreadMessagesCount = createAsyncThunk(
  'pageInfo/unreadMessagesCount',
  async ({ loanFileId, apiToken }) => {
    const url = Route.api.loanFile.messenger.toggle({ loanFileId })
    const headers = { Authorization: `Token ${apiToken}` }
    const { data: { unreadCount } } = await axiosClient.post(url, {}, { headers, baseURL: '' })
    return unreadCount
  }
)
