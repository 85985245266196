import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexPaymentTasksAPI } from './asyncActions'

const paymentTasksSlice = createSlice({
  name: 'payment_tasks',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexPaymentTasksAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexPaymentTasksAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexPaymentTasksAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default paymentTasksSlice
