import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showVOEIRequestAPI = createAsyncThunk(
  'voei_requests/show',
  async ({ requestId }) => {
    const { data } = await JSONAPIClient
      .one('request', requestId)
      .get()
    return data
  }
)

export const updateVOEIRequestAPI = createAsyncThunk(
  'voei_requests/update',
  async ({ requestId, values }) => {
    const { data } = await JSONAPIClient
      .one('request', requestId)
      .patch({ ...values, id: requestId })
    return data
  }
)
