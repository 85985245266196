import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'

import { resetUnreadMessagesCount } from './asyncActions'

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState: {},
  reducers: {
    ...baseReducers,
    incrementUnreadMessagesCount(state) {
      state.unreadMessagesCount += 1
    },
  },
  extraReducers: {
    [resetUnreadMessagesCount.pending]() {
    },
    [resetUnreadMessagesCount.fulfilled](state, action) {
      state.unreadMessagesCount = action.payload
    },
    [resetUnreadMessagesCount.rejected]() {
    },
  },
})

export default pageInfoSlice
