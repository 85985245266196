import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexClosingTasksAPI = createAsyncThunk(
  'closing_task/index',
  async ({ loanFileId }) => {
    const { data } = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('closing_task')
      .get({ include: 'task_items' })
    return data
  }
)
