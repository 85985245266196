import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { updateVOEIRequestAPI, showVOEIRequestAPI } from './asyncActions'

const voeiRequestsSlice = createSlice({
  name: 'voei_requests',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showVOEIRequestAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showVOEIRequestAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [showVOEIRequestAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateVOEIRequestAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [updateVOEIRequestAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [updateVOEIRequestAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default voeiRequestsSlice
