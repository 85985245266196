import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexSignableDocumentsAPI, showSignableDocumentAPI } from './asyncActions'

const signableDocumentsSlice = createSlice({
  name: 'signable_documents',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexSignableDocumentsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexSignableDocumentsAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexSignableDocumentsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showSignableDocumentAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showSignableDocumentAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      if (+state.stagedRecord.id === +action.payload.id) state.stagedRecord = action.payload
      const recordIndex = state.records.findIndex(({ id }) => +id === +action.payload.id)
      if (recordIndex) state.records[recordIndex] = { ...state.records[recordIndex], ...action.payload }
    },
    [showSignableDocumentAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
  },
})

export default signableDocumentsSlice
