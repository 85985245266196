import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { createPlaidLinkTokenAPI } from './asyncActions'

const plaidLinkTokenSlice = createSlice({
  name: 'plaid_link_tokens',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createPlaidLinkTokenAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createPlaidLinkTokenAPI.fulfilled](state, action) {
      const { products, itemId } = action.payload
      if (products) {
        state.stagedRecord = action.payload
      }
      if (itemId) {
        state.records = action.payload
      }
      state.uiFlags.isCreating = false
    },
    [createPlaidLinkTokenAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default plaidLinkTokenSlice
